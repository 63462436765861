import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/photography/how-to-use-telephoto-lenses",
  "date": "2014-03-07",
  "title": "HOW TO USE TELEPHOTO LENSES",
  "author": "admin",
  "tags": ["photography"],
  "featuredImage": "feature.jpg",
  "excerpt": "Telephoto lenses are useful for wildlife and landscape photography, given their ability to enlarge distant subjects, but they’re also great for giving your photo an artistic look."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`They can make the depth of field appear more shallow by reducing the size difference between near and far objects, and produce beautiful images with your subject and a narrow view of the background let's discuss how to use telephoto lenses.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-07-how-to-use-telephoto-lenses/Tokina300mmReflex_256_1351577900.jpg",
        "alt": "Telephoto lenses"
      }}></img></p>
    <p>{`300 mm telephoto lens - a statue`}</p>
    <h2>{`OVERVIEW`}</h2>
    <p>{`If the focal length of a lens is greater than 70mm,  it is called  Medium Telephoto on a full-frame camera. But a lens is considered Full Telephoto when the focal length is greater than 135mm, with an angle of view of 15° at its widest dimension. But on a compact camera with a 3-4x or greater zoom capability, the telephoto is when the lens is fully zoomed.`}</p>
    <p>{`On a compact camera with a 3-4X or greater zoom lens, telephoto is simply when you've fully zoomed in. However, some compact cameras might require a special adapter in order to achieve full telephoto.`}</p>
    <p>{`The longer the focal length, the more you will notice the unique effects of a telephoto lens.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-07-how-to-use-telephoto-lenses/view-angle.jpg",
        "alt": "Maximum angles"
      }}></img></p>
    <p>{`The above diagrams depict the maximum angles that light rays can take when hitting your camera's sensor. The location where light rays cross is not necessarily equal to the focal length but is instead roughly proportional to this distance. The angle of view therefore still increases similarly.`}</p>
    <p>{`A common misconception of using telephoto lenses is that telephoto lenses are just for capturing distant objects. While this is a legitimate explanation, there are other reasons, and often times distant objects are better photographed by simply getting a little closer. Perhaps not so practical with a lion, but a pet or a person will likely appear better when they aren't photographed from afar. Why? `}<strong parentName="p">{`The distance from your subject actually changes your photo's perspective, even if your subject is still captured at the same size in your camera frame`}</strong>{`. Confused? More on this in the next section...`}</p>
    <h2>{`TELEPHOTO PERSPECTIVE`}</h2>
    <p>{`A telephoto lens is special because it has a narrow-angle of view — but what does this actually do? `}<strong parentName="p">{`A narrow-angle of view means that both the relative size and distance is normalized when comparing near and far objects`}</strong>{`. This causes nearby objects to appear similar in size compared to faraway objects — even if the closer object would actually appear larger in person. The reason for this is the angle of view:`}</p>
    <p><img parentName="p" {...{
        "src": "http://cdn.cambridgeincolour.com/images/tutorials/wa_wide-angle-of-view.png",
        "alt": "wide-angle lens - wide angle of view"
      }}></img></p>
    <p>{`(objects are very different sizes)`}</p>
    <p>{`![Telephoto Lens]`}<a parentName="p" {...{
        "href": "http://cdn.cambridgeincolour.com/images/tutorials/wa_narrow-angle-of-view.png"
      }}>{`http://cdn.cambridgeincolour.com/images/tutorials/wa_narrow-angle-of-view.png`}</a>{`)`}</p>
    <p><strong parentName="p">{`Telephoto Lens`}</strong></p>
    <p>{`(objects are similar in size)`}</p>
    <p>{`Even though the two cylinders above are the same distance apart, their relative sizes are very different when one uses either a wide-angle lens and telephoto lens to fill the frame with the closest cylinder. With a narrow-angle of view, further objects comprise a much greater fraction of the total angle of view.`}</p>
    <p>{`A misconception is that a telephoto lens affects perspective, but strictly speaking, this isn't true. Perspective is only influenced by where you are located when you take a photograph. However, in practical use, the very fact that you're using a telephoto lens may mean that you're far from your subject — which `}<inlineCode parentName="p">{`does`}</inlineCode>{`affect perspective.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-07-how-to-use-telephoto-lenses/EF-S_18-135mm_f35-56_IS_Sample2_tcm14-962716.jpg",
        "alt": "Focal length"
      }}></img></p>
    <p>{`Objects appear in proper proportion to one another with this 135 mm telephoto focal length.`}</p>
    <p>{`This normalization of relative size can be used to give a proper sense of scale. For full impact, you'll want to get as far as possible from the nearest subject in the scene (and zoom in if necessary).`}</p>
    <p>{`In the telephoto example to the left, the people in the foreground appear quite small compared to the background building. On the other hand, if a normal focal length lens were used, and one was closer to the foreground people, then they would appear much larger relative to the size of the building.`}</p>
    <p>{`However, `}<strong parentName="p">{`normalizing the relative size too much can make the scene appear static, flat and uninteresting`}</strong>{` since our eyes generally expect closer objects to be a little larger. Taking a photo of someone or something from very far away should, therefore, be done only when necessary.`}</p>
    <p><strong parentName="p">{`In addition to relative size, a telephoto lens can also make the distance between objects appear compressed`}</strong>{`. This can be beneficial when you're trying to emphasize the number of objects, or to enhance the appearance of congestion:`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-07-how-to-use-telephoto-lenses/5f5f8b31764e934646ac839a226603da98771aa1_0.jpg",
        "alt": "Exaggerated Crowd Density"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-07-how-to-use-telephoto-lenses/Part-II-Plantation-1024x655.jpg",
        "alt": "Exaggerated Tree Density"
      }}></img></p>
    <h2>{`BRINGING FAR AWAY SUBJECTS CLOSER`}</h2>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-07-how-to-use-telephoto-lenses/IMGP3295.jpg",
        "alt": "320 mm detail shot of a bird"
      }}></img></p>
    <p>{`320 mm detail shot of a bird`}</p>
    <p>{`Perhaps the most common use for a telephoto lens is to bring otherwise small and distant subjects closer, such as wildlife. This can enable a vantage on subjects not otherwise possible in real life. One should therefore pay careful attention to far off detail and texture.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2014-03-07-how-to-use-telephoto-lenses/best-lens-for-landscape-photography-Flickr-vinothchandar-2013-03-23-CC-BY-20.jpg",
        "alt": "Telephoto sunset"
      }}></img></p>
    <p>{`Telephoto sunset`}</p>
    <p>{`Furthermore, even if you were able to get a little closer to the subject, this may adversely impact the photograph because being closer might alter the subject's behavior. This is especially true when trying to capture candid photographs of people; believe it or not, people usually act differently when they're aware that someone is taking their photograph.`}</p>
    <p>{`Finally, consider this: since a telephoto lens encompasses a much narrower angle of view, you as the photographer can be much more selective with what you choose to contain within your camera frame. You might choose to capture just the region of the sky right around the sunset (left), just the surfer on their wave, or just a tight region around someone's interesting facial expression. This added selectivity can make for very simple and focused compositions.`}</p>
    <h2>{`LANDSCAPES & LAYERING`}</h2>
    <p><img parentName="p" {...{
        "src": "http://cdn.cambridgeincolour.com/images/tutorials/telephoto_layering2.jpg",
        "alt": "Example of telephoto layering - Mt. Baldy, California"
      }}></img></p>
    <p>{`However, such claims aren't completely unfounded. Telephoto lenses compress the sense of depth, whereas wide-angle lenses exaggerate the sense of depth. Since spaciousness is an important quality in many landscapes, the rationale is that wide-angle lenses are therefore better suited.`}</p>
    <p>{`However, telephoto landscapes just require different techniques. If you want to improve the sense of depth, a common telephoto technique is to compose the scene so that it's comprised of layered subject matter at distinctly different distances. For example, the closest layer could be a foreground set of trees, the subsequent layers could be successively more distant hillsides, and the furthest layer could be the sky, ocean, and/or all other seemingly equidistant background objects.`}</p>
    <p><img parentName="p" {...{
        "src": "http://cdn.cambridgeincolour.com/images/tutorials/telephoto_layering1.jpg",
        "alt": "Example of telephoto layering - Mt Hamilton, California"
      }}></img></p>
    <p>{`165 mm telephoto shot using layered subject matter`}</p>
    <p>{`In the above example, the image would have seemed much less three-dimensional without the foreground layer of trees on the hill. Similarly, the separate layers of trees, clouds and background mountainside also give the first example more depth. A telephoto lens can also enhance how photography in fog, haze or mist`}<a href="http://www.cambridgeincolour.com/tutorials/fog-photography.htm" target="_blank">{` `}</a>{`affect an image, since these lenses make distant objects appear closer.`}</p>
    <h2>{`POINT OF FOCUS`}</h2>
    <p>{`For a given subject distance, a telephoto lens captures the scene with a much shallower depth of field than does other lenses. Out of focus distant objects are also made much larger, which enlarges their blur. It's therefore critical that you achieve pinpoint accuracy with your chosen point of focus.`}</p>
    <p><img parentName="p" {...{
        "src": "http://cdn.cambridgeincolour.com/images/tutorials/telephoto_focus-location.jpg",
        "alt": "Cat amongst leaves"
      }}></img></p>
    <p>{`320 mm focal length - shallow depth of field telephoto shot of a cat amongst leaves`}</p>
    <p>{`In the above example, the foreground fence was less than a foot from the cat's face — yet it appears extremely out of focus due to the shallow depth of field. Even a misfocus of an inch could have therefore caused the cat's eyes to become blurred, which would have ruined the intent of the photograph.`}</p>
    <p>{`Fortunately, telephoto lenses are rarely subject to the "focus and recompose" errors caused by shorter focal lengths — primarily because one is often much further from their subject. This means that you can use your central autofocus point to achieve a focus lock, and then recompose your frame without worry of changing the distance at which objects are in sharpest focus.`}</p>
    <h2>{`MINIMIZING CAMERA SHAKE`}</h2>
    <p>{`A telephoto lens may have a significant impact on how easy it is to achieve a sharp handheld photograph. `}<strong parentName="p">{`Longer focal lengths require shorter exposure times to minimize blurring caused by shaky hands.`}</strong>{` Think of this as if one were trying to hold a laser pointer steady; when shining this pointer at a nearby object its bright spot ordinarily jumps around less than for objects further away.`}</p>
    <p><img parentName="p" {...{
        "src": "http://cdn.cambridgeincolour.com/images/tutorials/tripod_focal-length.png",
        "alt": "Camera shake focal length rule of thumb"
      }}></img></p>
    <p>{`Simulation of what happens when you try to aim a laser pointer at a point on a distant wall;
the larger absolute movements on the further wall are similar to what happens with camera shake when you are using a telephoto lens (since objects become more magnified).`}</p>
    <p><strong parentName="p">{`Reducing camera shake requires either shooting using a faster shutter speed or holding your camera steadier`}</strong>{`, or some combination of the two.`}</p>
    <p><strong parentName="p">{`To achieve a faster shutter speed`}</strong>{` you will need to use a larger aperture (such as going from f/8.0 to f/2.8) and/or increase the ISO speed. However, both of these options have drawbacks, since a larger aperture decreases depth of field, and a higher ISO speed increases image noise.`}</p>
    <p><strong parentName="p">{`To hold your camera steadier`}</strong>{`, you can (i) use your other hand to stabilize the lens, (ii) try taking the photo while crouching, or (iii) lean your body or lens against another solid object. However, using a camera tripod or monopod is the only truly consistent way to reduce camera shake.`}</p>
    <h2>{`TELEPHOTO LENSES & DEPTH OF FIELD`}</h2>
    <p>{`Note that I've been careful to say that telephoto lenses only decrease depth of field `}<inlineCode parentName="p">{`for a given subject distance`}</inlineCode>{`. A telephoto lens itself does not have less depth of field. Unfortunately, this is a common misconception. If you are magnifying your subject by the same amount (meaning that they fill the image frame by the same proportion), then a telephoto lens will give the same* depth of field as other lenses.`}</p>
    <p><strong parentName="p">{`*Technical Note`}</strong>{`: for situations of extreme magnification, the depth of field may differ by a small amount. However, this is an extreme case and is not relevant for the uses discussed in this page.`}</p>
    <p>{`The reason that telephoto lenses get the reputation of decreasing depth of field is not because of any inherent property with the lens itself. It's because of how they're most often `}<inlineCode parentName="p">{`used`}</inlineCode>{`. People usually magnify their subject matter a lot more with telephoto lenses than with lenses that have wider angles of view. In other words, people generally don't get further from their subject, so this subject ends up filling more of the frame. It's this higher magnification that is what causes the shallower depth of field.`}</p>
    <p><img parentName="p" {...{
        "src": "http://cdn.cambridgeincolour.com/images/tutorials/telephoto_bokeh2.jpg",
        "alt": "Example of distracting bokeh with a telephoto lens"
      }}></img></p>
    <p>{`Distracting out of focus background`}</p>
    <p>{`However, a telephoto lens does enlarge out of focus regions (called "bokeh"), since it enlarges the background relative to the foreground. This may give the `}<inlineCode parentName="p">{`appearance`}</inlineCode>{` of a shallower depth of field.`}</p>
    <p>{`One should, therefore, pay close attention to how a background will look and be positioned when it's out of focus. For example, poorly-positioned out of focus highlights may prove distracting for a foreground subject (such as in the parrot example).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      